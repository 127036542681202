import { defineApi } from '../common/api-definition';
import { NextPastType } from '../common/NextPastType';
import { CommonLoanView } from '../structured-loan/common-loan';
import { InitiateDrawdownEvent } from './EventType';
import { EventViewRequest } from './EventViewRequest';
import { InitiateDrawdownNextCommand } from './NextCommandType';

export type InitiateDrawdownPageData = NextPastType<InitiateDrawdownNextCommand, InitiateDrawdownEvent>;

export type InitiateDrawdownViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      sequenceId: string;
      drawdownId: string;
    }
  | EventViewRequest;

export type InitiateDrawdownView = {
  loanId: string;
  currency: string;
  loanName: string;
  facilityName: string;
  sequenceName: string;
  loanLevel: string;
  commitmentAmount: number;
  availableCommitmentAmount: number;
  commitmentAvailabilityEndDate?: string;
  sequenceId: string;
  downloadUrls: { [fileKey: string]: { url: string } } | undefined;
  pageData: InitiateDrawdownPageData;
  commonView: CommonLoanView;
  isUnwound: boolean;
};

export const initiateDrawdownViewApi = defineApi<{
  body: InitiateDrawdownViewRequest;
  response: InitiateDrawdownView;
}>({
  url: 'structured-loans/initiate-drawdown-view',
  method: 'post',
});
