import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CommonLoanView } from './common-loan';

export type RunningStatusView = {
  commonView: CommonLoanView;
};

export type RunningStatusRequest = {
  loanId: string;
};

export const runningStatusValidation = ObjectStripUnknown<RunningStatusRequest>({
  loanId: TextStripEmpty.required(),
}).required();

export const runningStatusApi = defineApi<{ body: RunningStatusRequest; response: RunningStatusView }>({
  url: 'structured-loans/running-status-view',
  method: 'post',
});
