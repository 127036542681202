import {
  AgreementMapType,
  DocuSignIdentityVerificationType,
  LoanCompanyType,
  LoanUserType,
  NegotiationType,
  RsCognitoUser,
  TemplateType,
} from '@realstocks/types';
import { applyMiddleware, createStore, Store } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import thunk from 'redux-thunk';
import { AppCombinedReducers } from './redux/redux';
import { ReduxRoutesAccessRights } from './redux/redux-access-rights/access-rights-reducer';
import { APP_ACTIONS_KEYS } from './redux/redux-app/app-actions';
import { LoadingStateType } from './redux/redux-app/app-selectors';
import { CHAT_ACTIONS } from './redux/redux-chat/chat-actions';
import { ReduxCompanyType } from './redux/redux-company/company-reducer';
import { ChatState } from './types/chat';
import { AgreementPageViewType } from './types/loans/AgreementPageViewType';
import { TNotification } from './types/notifications';
import { UserCompanyType } from './types/UserTypes';

export type AppState = {
  application: ApplicationState;
  auth: AuthState;
  company: ReduxCompanyType;
  chat: ChatState;
  loans: LoanState;
  notifications: NotificationsState;
  accessRights: ReduxRoutesAccessRights;
};

export type ApplicationState = {
  initialized: boolean;
  loading: LoadingStateType;
  invisibleOverlay: boolean;
  socketInstance: WebSocket | null;
  syncTimestamp: number;
};

export type AuthState = {
  isLoggedIn: boolean | null;
  currentUser: RsCognitoUser | null;
  companies: UserCompanyType[] | null;
};

export type NotificationsState = {
  newNotificationsWithActions: TNotification[];
  newNotificationsWithoutActions: TNotification[];
  allNotifications: TNotification[];
};

export type LoanState = {
  // Maps from loan ID to various details of the loan.
  loans: Record<string, NegotiationType>;
  agreementPageMap: Record<string, AgreementPageType>;

  agreementMap: AgreementMapType;
  ndaAgreementMap: AgreementMapType;

  companiesLoaded: boolean;
  companies: LoanCompanyType[] | null;

  usersLoaded: boolean;
  users: LoanUserType[];

  templatesLoaded: boolean;
  templates: TemplateType[];

  identityVerificationLoaded: boolean;
  identityVerification?: DocuSignIdentityVerificationType;
};

export type AgreementPageType = {
  pageView?: AgreementPageViewType;
  selectedSection?: string;
};

export type AppStore = Store<AppState>;

const syncConfig = {
  channel: 'realstocks',
  whitelist: [CHAT_ACTIONS.setChatMessages, CHAT_ACTIONS.resetChatState, APP_ACTIONS_KEYS.setSyncTimestamp],
};

const store = createStore(
  enableBatching(AppCombinedReducers),
  composeWithDevTools(applyMiddleware(createStateSyncMiddleware(syncConfig), thunk))
);
initMessageListener(store);

export default store;
