import { ExternalIds } from '../common/ExternalIds';
import { SLPlatformInteractionsType } from '../common/PlatformInteractionsType';
import { SuccessOrErrorType } from '../common/SuccessOrErrorType';
import { InformationUndertakingSpecDraftType } from '../covenants/InformationUndertakingSpecType';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { InterestRatePeriodType } from '../fa/NegotiationType';
import { SLBookingModel } from '../loan/BookingModelType';
import { DateRolling } from '../loan/DateRolling';
import { DayCountConventionType } from '../loan/DayCountConventionType';
import { InterestPeriodAlignmentType } from '../loan/InterestPeriodAlignmentType';
import { LCEInterestPeriodBasis } from '../loan/lce-interest-period-basis';
import { StructuredLoanValidationIssue } from '../loan/StructuredLoanValidationIssue';
import { BankAccountRestrictions } from './BankAccountRestrictions';
import { DistributionRestrictions } from './DistributionRestrictions';
import { SLInterestRateType } from './InterestRateType';
import { SecuritiesAndGuarantees } from './SecuritiesAndGuarantees';
import { SLPropertyDetails, SLPropertyType } from './SLPropertyType';
import {
  EndAvailabilityPeriodType,
  SLDocumentCategoryType,
  SLFeesType,
  StructuredAgreementType,
  StructuredFacilityType,
  StructuredSubventionType,
  StructuredTranchesType,
} from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';
import { StructuredStartConfigurationType } from './structured-start-configuration-type';

export type RunEventReturnType = string | SuccessOrErrorType<true, StructuredLoanValidationIssue | string | undefined>;

/**
 * If you remove an event from this list, or change the key of an event, move the old/deleted event to
 * [SLEventTypeHistorical.ts]({@link ./SLEventTypeHistorical.ts}).
 */
export type SLEventType =
  | SLAddMetricEventType
  | SLRemoveMetricEventType
  | SLAddReportingEventEventType
  | SLRemoveReportingEventEventType
  | AllocatedLoanAmountsUpdateEvent
  | SLFacilityIntroUpdateEventType
  | SLSequenceUtilisationUpdateEventType
  | SLSequenceTermSheetUpdateEventType
  | SLSequenceTermSheetAmendEvent
  | SLSequenceConstructionSheetUpdateEventType
  | SLStartSchedulesUpdateEvent
  | SLStartBookingModelUpdateEventType
  | SLStartBookingModelAmendEvent
  | SLExternalIDsEventType
  | SLStructureUpdateEventType
  | SLStructureAmendEvent
  | SLAgreementPartiesUpdateEventType
  | SLAgreementReportingEventUpdateEventType
  | SLAgreementInformationUndertakingUpdateEventType
  | SLAgreementPropertyUpdateEventType
  | SLAgreementMetricUpdateEvent
  | SLRunLoanEventType
  | SLUpdateState
  | SLUpdateCustomPlatformInteractionsEventType
  | SLUpdateLoanFees
  | UpdateLoanDistributionsAndAccounts
  | UpdateLoanDocumentsAndSecurities
  | SLStartLoanAmendEventType
  | SLCancelLoanAmendEventType
  | SLCommitLoanAmendEventType
  | SLExternalIDsAmendEventType
  | SLAgreementPartiesAmendEventType
  | SLAgreementPropertyAmendEventType
  | SLFacilityIntroAmendEventType
  | SLUnwindStructuredLoanEvent
  | SLMigrateStructuredLoanEvent;

export type SLNewEventType = {
  type: 'new';
  agreement: Pick<
    StructuredAgreementType,
    'loan_name' | 'quantum_currency' | 'borrower_company_id' | 'agent' | 'external_id' | 'owner_company_id'
  >;
  facilities: {
    properties: Pick<SLPropertyType, 'name'>[];
    sequences: {
      utilisations?: {}[];
      construction?: {};
      tranches: {}[];
    }[];
  }[];
};

export type UpdateLoanDistributionsAndAccounts = {
  type: 'loan-distribution-and-accounts-update';
  loanId: string;
  version: number;
  distributionRestrictions?: DistributionRestrictions;
  bankAccountRestrictions?: BankAccountRestrictions;
};

export type UpdateLoanDocumentsAndSecurities = {
  type: 'loan-documents-and-securities-update';
  loanId: string;
  version: number;
  documentCategories: SLDocumentCategoryType[];
  securitiesAndGuarantees?: SecuritiesAndGuarantees;
};

export type SLUpdateLoanFees = {
  type: 'loan-fees-update';
  loanId: string;
  version: number;
  fees: SLFeesType;
};

export type SLAddMetricEventType = {
  type: 'add-metric-update';
  loanId: string;
  version: number;
  metrics?: {
    metricSelected: LoanMetricDraftType;
    metricFormulaLeft?: LoanMetricDraftType;
    metricFormulaRight?: LoanMetricDraftType;
  };
};

export type SLRemoveMetricEventType = {
  type: 'remove-metric-update';
  metricId: string;
  loanId: string;
  version: number;
};

export type SLAddReportingEventEventType = {
  type: 'add-reporting-event-update';
  loanId: string;
  version: number;
};
export type SLRemoveReportingEventEventType = {
  type: 'remove-reporting-event-update';
  loanId: string;
  reportingEventId: string;
  version: number;
};

export type SLUpdateCustomPlatformInteractionsParams = {
  loanId: string;
};

export type SLUpdateCustomPlatformInteractionsEventType = {
  type: 'custom-notifications-update';
  loanId: string;
  version: number;
  platformInteractions: { [companyId: string]: Partial<SLPlatformInteractionsType> };
};

export type SLAgreementPartiesUpdateEventType = {
  type: 'agreement-parties-update';
  loanId: string;
  version: number;
  agreement: Pick<
    StructuredAgreementType,
    | 'loan_name'
    | 'quantum_currency'
    | 'agreement_date'
    | 'purpose'
    | 'applicable_law'
    | 'external_id'
    | 'borrower_company_id'
    | 'owner_company_id'
    | 'agent'
    | 'guarantor_name'
    | 'legal_entity'
    | 'cap_table'
    | 'joint_venture'
    | 'responsible_user_ids'
  >;
};

/**
 * We use the same event for update and amend.
 */
export type AllocatedLoanAmountsUpdateEvent = {
  type: 'allocated-loan-amounts-update';
  loanId: string;
  facilityId: string;
  version: number;

  /**
   * map of propertyId -> allocated loan amount ratio if known
   */
  allocatedLoanAmounts: { [propertyId: string]: number | undefined };
};

export type SLFacilityIntroUpdateEventType = {
  type: 'facility-intro-update';
  loanId: string;
  facilityId: string;
  version: number;
  facility: Pick<StructuredFacilityType, 'legal_entity'>;
};

export type SLSequenceUtilisationUpdateEventType = {
  type: 'sequence-utilisation-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  utilisationId: string;
  version: number;
  utilisation: {
    sequenceCurrency?: string;
    quantum_availability_period?: string;
    start_quantum_availability_period?: string;
    commitment?: number;
    quantum_utilisation_request_time?: string;
    quantum_utilisation_request_days?: number;
  };
};

export type SLSequenceTermSheetUpdateEventType = {
  type: 'sequence-term-sheet-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  termsId: string;
  version: number;
  terms: Omit<StructuredTranchesType, 'id' | 'name'>;
};

export type SLSequenceTermSheetAmendEvent = {
  type: 'sequence-term-sheet-amend';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  termsId: string;
  version: number;
  terms: Omit<StructuredTranchesType, 'id' | 'name'>;
};

export type SLSequenceConstructionSheetUpdateEventType = {
  type: 'sequence-construction-sheet-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  constructionId: string;
  version: number;
  sequenceCurrency?: string;
  constructionValues: {
    end_availability_period?: EndAvailabilityPeriodType;
    start_availability_period?: string;
    commitment?: number;
    day_count_convention?: DayCountConventionType;
    holiday_calendar?: string[];
    interest_late_payment?: number;
    interest_capitalised?: boolean;
    interest_percentage_capitalised?: number;
    points_interest_percentage_capitalised?: number;
    interest_period_basis?: LCEInterestPeriodBasis;
    interest_period_alignment?: InterestPeriodAlignmentType;
    interest_rate_period?: InterestRatePeriodType;
    interest_rate?: SLInterestRateType;
    date_rolling?: DateRolling;
    subvention?: StructuredSubventionType;
    expected_termination_date?: string;
    first_longer_interest_period?: string;
  };
};

export type SLStartSchedulesUpdateEvent = {
  type: 'start-schedules-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  version: number;
  utilisation_date?: string;
  utilisation_amount?: number;
  trancheSchedules: {
    [trancheId: string]: {
      subventionCoverage: { periodIndex: number; value: number }[];
      interestRate: { periodIndex: number; value: number }[];
      amortisationAmount: { periodIndex: number; value: number }[];
    };
  };
};

export type SLExternalIDsEventType = {
  type: 'external-ids-update';
  loanId: string;
  version: number;
  externalIds: ExternalIds;
};

export type SLStartBookingModelUpdateEventType = {
  type: 'start-booking-model-update';
  loanId: string;
  facilityId: string;
  version: number;
  booking_model: SLBookingModel;
};

export type SLStartBookingModelAmendEvent = {
  type: 'start-booking-model-amend';
  loanId: string;
  facilityId: string;
  version: number;
  booking_model: SLBookingModel;
};

export type SLStructureUpdateFacility = {
  id?: string;
  name: string;
  properties: SLStructureUpdateProperty[];
  sequences: SLStructureUpdateSequence[];
};

export type SLStructureUpdateProperty = {
  id?: string;
  name: string;
};

export type SLStructureUpdateSequence = {
  id?: string;
  name: string;
  construction?: {}; // Set for construction tranches.
};

export type SLStructureUpdateEventType = {
  type: 'loan-structure-update';
  loanId: string;
  version: number;
  facilities: SLStructureUpdateFacility[];
};

export type SLStructureAmendEvent = {
  type: 'loan-structure-amend';
  loanId: string;
  version: number;
  facilities: SLStructureUpdateFacility[];
};

export type SLAgreementMetricUpdateEvent = {
  type: 'agreement-metric-update';
  loanId: string;
  metricId: string;
  version: number;
  metric: Omit<LoanMetricDraftType, 'id'>;
};

export type SLAgreementInformationUndertakingUpdateEventType = {
  type: 'agreement-information-undertaking-update';
  loanId: string;
  iuId: string;
  version: number;
  information_undertaking: Omit<InformationUndertakingSpecDraftType, 'id'>;
};

export type SLAgreementReportingEventUpdateEventType = {
  type: 'agreement-reporting-event-update';
  loanId: string;
  reId: string;
  version: number;
  reporting_event: Omit<SLReportingEventConfigDraft, 'id'>;
};

export type SLRunLoanEventType = {
  type: 'run-structured-loan-update';
  loanId: string;
  version: number;
};

export type SLAgreementPropertyUpdateEventType = {
  type: 'agreement-property-update';
  loanId: string;
  facilityId: string;
  propertyId: string;
  version: number;
  property: Partial<SLPropertyDetails>;
};

export type SLUpdateState = {
  type: 'state-update';
  loanId: string;
  version: number;
  state: StructuredLoanState;
};

export type SLImportEventType = {
  type: 'import-loan';
  schema: string;
  loan: StructuredAgreementType;
  startConfiguration: StructuredStartConfigurationType;
  skipValidation: boolean;
};

export type SLExportEventType = {
  type: 'export-loan';
  loanId: string;
};

// ***************** AMEND EVENT TYPES *****************

export enum AmendEventType {
  START_LOAN_AMEND = 'start-loan-amend',
  CANCEL_LOAN_AMEND = 'cancel-loan-amend',
  AMEND_EXTERNAL_IDS = 'external-ids-amend',
}

export type SLStartLoanAmendEventType = {
  type: 'start-loan-amend';
  loanId: string;
  version: number;
};

export type SLCancelLoanAmendEventType = {
  type: 'cancel-loan-amend';
  loanId: string;
  version: number;
};

export type SLCommitLoanAmendEventType = {
  type: 'commit-loan-amend';
  loanId: string;
  version: number;
};

export type SLExternalIDsAmendEventType = {
  type: 'external-ids-amend';
  loanId: string;
  version: number;
  externalIds: ExternalIds;
};

export type SLAgreementPartiesAmendEventType = Omit<SLAgreementPartiesUpdateEventType, 'type'> & {
  type: 'agreement-parties-amend';
};

export type SLAgreementPropertyAmendEventType = Omit<SLAgreementPropertyUpdateEventType, 'type'> & {
  type: 'agreement-property-amend';
};

export type SLFacilityIntroAmendEventType = Omit<SLFacilityIntroUpdateEventType, 'type'> & {
  type: 'facility-intro-amend';
};

export type SLUnwindStructuredLoanEvent = {
  type: 'unwind';
  loanId: string;
  version: number;

  // The version of the structured loan to which the loan is being unwinded.
  unwindVersion: number;
};

/** Replacement of a loan done through a migrations. */
export type SLMigrateStructuredLoanEvent = {
  type: 'migrate-structured-loan';
  loanId: string;
  version: number;

  /** The migrated loan that replaces the existing loan. */
  loan: StructuredAgreementType;
};
