import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { NextPastType } from '../common/NextPastType';
import { InformationUndertakingSpecType } from '../covenants/InformationUndertakingSpecType';
import {
  SLInformationDraftCollections,
  SLInformationUndertakingDocuments,
} from '../covenants/InformationUndertakingType';
import { LoanMetricType } from '../covenants/LoanMetricType';
import { CommonLoanView } from '../structured-loan/common-loan';
import { ObjectStripUnknown, RequiredDate } from '../validation/atoms';
import { literal, sumTypeRequired } from '../validation/base';
import { EventViewRequestValidation } from '../validation/EventViewRequestValidation';
import { SLInformationUndertakingEventType } from './EventType';
import { EventViewRequest } from './EventViewRequest';
import { IntervalDetailsType } from './IntervalDetailsType';
import { SLInformationUndertakingNextCommandType } from './NextCommandType';

export type InformationUndertakingViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      informationUndertakingId: string;
      checkDate: string;
    }
  | EventViewRequest;

export type InformationUndertakingViewType = {
  version: number;
  loanId: string;
  checkDate: string;
  name: string;
  currency: string;
  metrics: { [metricId: string]: LoanMetricType };
  nextPast: IUNextPastType;
  intervalDetails: IntervalDetailsType;
  informationUndertaking: InformationUndertakingSpecType;
  entities?: IUEntitiesView;
  collections?: SLInformationDraftCollections;
  documents: SLInformationUndertakingDocuments;
  previousCollections: PreviousInformationUndertakingCollection[];
  state: 'draft' | 'submitted' | 'skipped';
  debug: boolean;
  commonView: CommonLoanView;
};

export type PreviousInformationUndertakingCollection = {
  intervalDetails: IntervalDetailsType;
  collections: SLInformationDraftCollections;
  documents: SLInformationUndertakingDocuments;
};

export type IUEntitiesView =
  | {
      type: 'legal_entity';
      entries: IULegalEntityEntryView[];
    }
  | {
      type: 'property';
      entries: IUPropertyEntryView[];
    };

export type IULegalEntityEntryView = {
  id: string;
  name: string;
  spv_registration_number: string;
  external_id: string;
  facilityName: string;
};

export type IUPropertyEntryView = {
  id: string;
  name: string;
  property_code?: string;
  facilityName: string;
};

export type IUNextPastType = NextPastType<SLInformationUndertakingNextCommandType, SLInformationUndertakingEventType>;

export const informationUndertakingViewApi = defineApi<{
  body: InformationUndertakingViewRequest;
  response: InformationUndertakingViewType;
}>({
  url: 'structured-loans/information-undertaking-view',
  method: 'post',
});

export const informationUndertakingViewRequestValidation = sumTypeRequired('type', {
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: Yup.string().required(),
    informationUndertakingId: Yup.string().required(),
    checkDate: RequiredDate(),
  }),
  event: EventViewRequestValidation,
});
