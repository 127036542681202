import { defineApi } from '../common/api-definition';
import { Option } from '../common/Option';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { CommonLoanView } from './common-loan';
import { StructuredLoanState } from './structured-loan-state';

export type AgreementMetricView = {
  loanId: string;
  state: StructuredLoanState;
  version: number;
  metric: LoanMetricDraftType;

  /** All metric configurations of the loan. */
  loanMetrics: LoanMetricDraftType[];

  metricOptions: Option[];
  commonView: CommonLoanView;
};

export const agreementMetricViewApi = defineApi<{
  body: { loanId: string; metricId: string };
  response: AgreementMetricView;
}>({
  url: 'structured-loans/agreement-metric-view',
  method: 'post',
});
