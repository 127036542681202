import { defineApi } from '../common/api-definition';
import { StructuredLoanState } from './structured-loan-state';

export type LoanHealthEntry = {
  loanId: string;

  /** The state of the structured loan object. Only started loans are included
        in the listing. */
  loanState: StructuredLoanState;

  /** Whether the loan can be succesfully fetched from the LCE. */
  lceState: 'ok' | 'error';
};

export type LoanHealthView = {
  loans: LoanHealthEntry[];
};

export const loanHealthApi = defineApi<{
  response: LoanHealthView;
}>({
  url: 'structured-loans/validate-all-loans',
  method: 'post',
});
