import { defineApi } from '../common/api-definition';

export type MigrateStructuredLoansResponse = {
  type: 'ok' | 'error' | 'already-migrated';
  messages: string[];
  versionedItems: { [loanId: string]: number | undefined };
  latestItems: { [loanId: string]: number | undefined };
  repositoryBefore: 'DDB' | 'PG';
  repositoryAfter: string;
};

export const migrateStructuredLoansApi = defineApi<{
  response: MigrateStructuredLoansResponse;
}>({
  url: 'structured-loans/migrate-structured-loans',
  method: 'post',
});
