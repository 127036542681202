import { defineApi } from '../common/api-definition';
import { CommonLoanView } from '../structured-loan/common-loan';
import { EventViewRequest } from './EventViewRequest';
import { PeriodDetailsWithDays } from './PeriodDetailsType';

export type CustomDynamicScheduleViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      sequenceId: string;
      periodIndex: number;
    }
  | EventViewRequest;

export type CustomDynamicScheduleView = {
  type: 'next' | 'past';
  loanId: string;
  sequenceId: string;
  version: number;
  loanLevel: string;
  currency: string;
  externalTrancheId: string | undefined;
  currentPeriod: PeriodDetailsWithDays;
  periods: {
    isCurrentPeriod: boolean;
    isEditable: boolean;
    periodIndex: number;
    valueDate: string;
    outstandingDebtStart: number;
    interestAmount?: number;
    amortisationAmount?: number;
    prepaymentAmount: number;
  }[];
  commonView: CommonLoanView;
};

export const customDynamicScheduleViewApi = defineApi<{
  body: CustomDynamicScheduleViewRequest;
  response: CustomDynamicScheduleView;
}>({
  url: 'structured-loans/custom-dynamic-schedule-view',
  method: 'post',
});
